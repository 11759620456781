import fetch from "isomorphic-fetch";
import get from "lodash.get";
import { fetch as router } from "helpers/router";
import request from "utils/request";

// -- Actions

const FETCH_CAMPAIGNS = "homepage/issueSelector/FETCH_CAMPAIGNS";
const FETCH_CAMPAIGNS_SUCCESS = "homepage/issueSelector/FETCH_CAMPAIGNS_SUCCESS";

const POST_SELECTED_CATEGORY = "homepage/issueSelector/POST_SELECTED_CATEGORY";
const POST_SELECTED_CATEGORY_SUCCESS = "homepage/issueSelector/POST_SELECTED_CATEGORY_SUCCESS";

// -- Reducer

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_CAMPAIGNS:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        campaigns: {
          newest: action.response.newest.data,
          mostRaised: action.response.mostRaised.data,
          trending: action.response.trending.data,
          featured: action.response.featured,
        },
      };
    case POST_SELECTED_CATEGORY:
      return {
        ...state,
        visitorSelectedCategory: action.category === "all" ? "" : action.category,
      };
    default:
      return state;
  }
}

// -- Action Creators

// Campaigns
export const fetchCampaigns = (category) => ({
  type: FETCH_CAMPAIGNS,
  category,
});

export const fetchCampaignsSuccess = (response) => ({
  type: FETCH_CAMPAIGNS_SUCCESS,
  response,
});

// Preferences
export const postSelectedCategory = (category) => ({
  type: POST_SELECTED_CATEGORY,
  category,
});

export const postSelectedCategorySuccess = () => ({
  type: POST_SELECTED_CATEGORY_SUCCESS,
});

// -- Async Action Creators

export const fetchCampaignsForCategory = (category) => (dispatch, getState) => {
  dispatch(fetchCampaigns(category));

  const countryCode = get(getState(), "issueSelector.visitorCountryCode");
  const countryCodeParameter = countryCode ? `&countryCode=${countryCode}` : "";

  const url = `${router("api.v2.campaigns.highlights")}?category=${category}${countryCodeParameter}`;

  return fetch(request(url))
    .then((response) => response.json())
    .then((json) => { dispatch(fetchCampaignsSuccess(json)); });
};

export const storeSelectedCategory = (category) => (dispatch) => {
  dispatch(postSelectedCategory(category));

  const url = router("api.v2.preferences.category.store", { category });

  return fetch(request(url, "PUT"))
    .then((response) => response.json())
    .then(() => { dispatch(postSelectedCategorySuccess()); });
};

export const fetchCampaignsAndStoreSelectedCategory = (category) => (dispatch) => {
  dispatch(fetchCampaignsForCategory(category));
  dispatch(storeSelectedCategory(category));
};

export const fetchAllCampaignsAndResetSelectedCategory = () => (dispatch) => {
  dispatch(fetchCampaignsForCategory("all"));
  dispatch(storeSelectedCategory("all"));
};

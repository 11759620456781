import React from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

import TextHeading from "../TextHeading";

import "./_style.scss";

const PageBlockHeader = ({
  heading,
  preHeading,
  subHeading,
  ...other
}) => (
  <div
    className={
        classNames({
          "page-block-header": true,
        })
      }
    {...other}
  >
    {preHeading
        && (
        <div className="page-block-header__preheading">
          {typeof preHeading === "string"
            ? <TextHeading level={2} light marginBottom="half" size={7} uppercase>{preHeading}</TextHeading>
            : preHeading}
        </div>
        )}
    <div className="page-block-header__heading">
      {typeof heading === "string"
        ? <TextHeading level={3} size={3}>{heading}</TextHeading>
        : heading}
    </div>
    {subHeading
        && (
        <div className="page-block-header__subheading">
          {typeof subHeading === "string"
            ? <TextHeading level={0} light marginTop="single" size={6}>{subHeading}</TextHeading>
            : subHeading}
        </div>
        )}
  </div>
);

PageBlockHeader.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  preHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default PageBlockHeader;

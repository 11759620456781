import {
  createStore, applyMiddleware, compose, combineReducers,
} from "redux";
import thunkMiddleware from "redux-thunk";

import issueSelectorReducer from "./containers/IssueSelector/state";

export default function configureStore(initialState = {}) {
  const rootReducer = combineReducers({
    issueSelector: issueSelectorReducer,
  });

  return createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(thunkMiddleware),
      // redux chrome devtools:
      // https://github.com/zalmoxisus/redux-devtools-extension
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
    ),
  );
}

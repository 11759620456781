import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";

import VisibilitySensor from "react-visibility-sensor";

import Carousel from "components/Carousel";
import SellingPoint from "components/SellingPoint";
import dataScroll from "helpers/dataScroll";
import configureStore from "./store";
import IssueSelector from "./containers/IssueSelector";

import "./style.scss";

// Issue Selector

// Initial State (from global variable - if present)

const initialState = {
  issueSelector: window.Chuffed.issueSelector || {},
};

const store = configureStore(initialState);

render(
  <Provider store={store}>
    <IssueSelector />
  </Provider>,
  document.getElementById("js-issue-selector"),
);

// Selling Points

const sellingPointsSettings = {
  arrows: false,
  autoplay: false,
  autoplaySpeed: 4 * 1e3,
  className: "carousel --fade",
  dots: true,
  dotsClass: "slick-dots carousel__dots --bigger --inverse",
  fade: true,
  infinite: true,
  speed: 500,
};

render(
  <VisibilitySensor
    partialVisibility
  >
    {({ isVisible }) => (
      <Carousel
        settings={{
          ...sellingPointsSettings,
          autoplay: isVisible,
        }}
        key={isVisible ? "0" : "1"}
      >
        <SellingPoint
          heading="0% fees"
        >
          “We’re a global crowdfunding platform that offers zero fees for all your campaigns.
          That means no setup fees, no hidden 5% platform fees, no ongoing subscription fees
          for running your campaign. How? Donors can choose to support the platform or not.”
        </SellingPoint>
        <SellingPoint
          heading="Only for social causes"
        >
          “Chuffed only crowdfunds non-profit and social enterprise projects.
          That’s it. That means we know what works for social causes, and our
          community is only interested in projects like yours.”
        </SellingPoint>
        <SellingPoint
          heading="Keep 100% of what you raise"
        >
          “Need $100,000 to help 100 people but only raise $99,000? On most
          platforms, you’d lose it all. With Chuffed, you get to keep what
          you raise and help those 99 people.”
        </SellingPoint>
      </Carousel>
    )}
  </VisibilitySensor>,
  document.getElementById("js-selling-points"),
);

dataScroll();

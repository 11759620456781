import { createSelector } from "reselect";

// Issue Selector Selectors

// select: state.campaigns
export const selectIssueSelector = (state) => state.issueSelector;
export const selectCampaigns = (state) => state.issueSelector.campaigns;

// select: issueSelector.campaigns.trending
export const makeSelectTrendingCampaigns = () => createSelector(
  selectCampaigns,
  (campaignsState) => campaignsState.trending,
);

// select: issueSelector.campaigns.mostRaised
export const makeSelectMostRaisedCampaigns = () => createSelector(
  selectCampaigns,
  (campaignsState) => campaignsState.mostRaised,
);

// select: issueSelector.campaigns.mostRaised
export const makeSelectFeaturedCampaign = () => createSelector(
  selectCampaigns,
  (campaignsState) => campaignsState.featured,
);

// select: issueSelector.visitorSelectedCategory
export const makeSelectVisitorSelectedCategory = () => createSelector(
  selectIssueSelector,
  (issueSelectorState) => issueSelectorState.visitorSelectedCategory,
);

// select: issueSelector.categories
export const makeSelectCategories = () => createSelector(
  selectIssueSelector,
  (issueSelectorState) => issueSelectorState.categories,
);

// select: issueSelector.isLoading
export const makeSelectIsLoading = () => createSelector(
  selectIssueSelector,
  (issueSelectorState) => issueSelectorState.isLoading,
);

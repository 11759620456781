import React from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import CategoryFilter from "components/CategoryFilter";
import Category from "components/Category";
import CampaignListing from "components/CampaignListing";
import CampaignCard from "components/CampaignCard";
import LinkButton from "components/LinkButton";
import PageBlock from "components/PageBlock";
import PageBlockAction from "components/PageBlockAction";
import PageBlockHeader from "components/PageBlockHeader";
import {
  fetchCampaignsAndStoreSelectedCategory,
  fetchAllCampaignsAndResetSelectedCategory,
} from "./state";

import {
  makeSelectCategories,
  makeSelectFeaturedCampaign,
  makeSelectIsLoading,
  makeSelectMostRaisedCampaigns,
  makeSelectTrendingCampaigns,
  makeSelectVisitorSelectedCategory,
} from "./selectors";

const allCategoriesName = "All Social Causes";

const IssueSelector = ({
  categories,
  featuredCampaign,
  isLoading,
  mostRaisedCampaigns,
  onCategorySelect,
  onAllCategoriesSelect,
  trendingCampaigns,
  visitorSelectedCategory,
}) => {
  const categoryItems = categories.map((categoryName, i) => (
    <Category
      key={i}
      onClick={onCategorySelect}
      isSelected={categoryName === visitorSelectedCategory}
    >
      {categoryName}
    </Category>
  ));

  const mostSuccessfulItems = mostRaisedCampaigns.map((campaign, i) => (
    <CampaignCard campaign={campaign} key={i} />
  ));

  const trendingItems = trendingCampaigns.map((campaign, i) => (
    <CampaignCard campaign={campaign} key={i} />
  ));

  return (
    <div>
      <PageBlock isNarrow look="light" size="short">
        <PageBlockHeader
          preHeading="Social Cause Campaigns"
          heading="Let's take action on:"
        />
        <CategoryFilter>
          <Category
            onClick={onAllCategoriesSelect}
            isSelected={!visitorSelectedCategory}
          >
            {allCategoriesName}
          </Category>
          {categoryItems}
        </CategoryFilter>
      </PageBlock>
      <PageBlock isNarrow look="light">
        <PageBlockHeader
          preHeading="Category:"
          heading={visitorSelectedCategory || allCategoriesName}
          subHeading={(featuredCampaign) ? null : "There are no campaigns to display at the moment."}
        />
        {featuredCampaign
          && (
          <CampaignListing
            isLoading={isLoading}
            isNarrow
            isSingleItem
          >
            <CampaignCard
              isFeatured
              isLoading={isLoading}
              campaign={featuredCampaign}
            />
          </CampaignListing>
          )}
        {(mostSuccessfulItems && mostSuccessfulItems.length > 0)
          && (
          <CampaignListing
            isNarrow
            isLoading={isLoading}
            headingText="Most Successful"
            headingUrl="/discover"
          >
            {mostSuccessfulItems}
          </CampaignListing>
          )}
        {(trendingItems && trendingItems.length > 0)
          && (
          <CampaignListing
            isNarrow
            isLoading={isLoading}
            headingText="Gaining Momentum"
            headingUrl="/discover"
          >
            {trendingItems}
          </CampaignListing>
          )}
        <PageBlockAction
          action={
            <LinkButton isFullWidth look="secondary-outline" href="/discover">All Campaigns</LinkButton>
          }
          isCentered
        />
      </PageBlock>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  categories: makeSelectCategories(),
  featuredCampaign: makeSelectFeaturedCampaign(),
  isLoading: makeSelectIsLoading(),
  mostRaisedCampaigns: makeSelectMostRaisedCampaigns(),
  trendingCampaigns: makeSelectTrendingCampaigns(),
  visitorSelectedCategory: makeSelectVisitorSelectedCategory(),
});

const mapDispatchToProps = (dispatch) => ({
  onAllCategoriesSelect: () => {
    dispatch(fetchAllCampaignsAndResetSelectedCategory());
  },

  onCategorySelect: (e) => {
    dispatch(fetchCampaignsAndStoreSelectedCategory(e.target.textContent));
  },
});

// export default connect(mapStateToProps)(IssueSelector);
export default connect(mapStateToProps, mapDispatchToProps)(IssueSelector);

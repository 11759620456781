import React from "react";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import "./_style.scss";

import Slider from "react-slick";

const defaultSettings = {
  dots: true,
  slidesToShow: 3,
  // infinite: true,
  slidesToScroll: 3,
  arrows: true,
  responsive: [
    {
      breakpoint: 980,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
      },
    },
    {
      breakpoint: 730,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 485,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ],
};

const Carousel = ({ settings, children, ...other }) => (
  <div className="carousel">
    <Slider
      {...(settings || defaultSettings)}
      {...other}
    >
      {React.Children.map(children, (child) => (
        <div>
          {child}
        </div>
      ))}
    </Slider>
  </div>
);

export default Carousel;

import React from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

import "./_style.scss";

const PageBlock = ({
  children,
  isNarrow,
  isNoPaddingBottom,
  isNoPaddingTop,
  look,
  size,
  ...other
}) => (
  <div
    className={
        classNames({
          "page-block": true,
          "--no-padding-bottom": isNoPaddingBottom,
          "--no-padding-top": isNoPaddingTop,
          [`--${look}`]: !!look,
          [`--${size}`]: !!size,
        })
      }
    {...other}
  >
    <div className={
        classNames({
          "page-block__content": true,
          "--narrow": isNarrow,
        })
      }
    >
      {children}
    </div>
  </div>
);

PageBlock.defaultProps = {
  look: "default",
};

PageBlock.propTypes = {
  children: PropTypes.node.isRequired,
  look: PropTypes.oneOf([
    "default",
    "light",
    "brand",
    "brand-light",
  ]),
  isNarrow: PropTypes.bool,
  isNoPaddingBottom: PropTypes.bool,
  isNoPaddingTop: PropTypes.bool,
  size: PropTypes.oneOf(["short", "tall"]),
};

export default PageBlock;

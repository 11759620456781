import React from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

import "./_style.scss";

const Category = ({
  children,
  href,
  isSelected,
  onClick,
  ...other
}) => {
  let Element = "span";
  if (href) {
    Element = "a";
  }
  if (onClick) {
    Element = "button";
  }

  return (
    <Element
      className={
        classNames({
          category: true,
          "--selected": isSelected,
          "--link": href,
          "--button": onClick,
        })
      }
      {...other}
      href={href}
      onClick={(e) => { isSelected ? e.preventDefault() : onClick(e); }}
      {...onClick ? { type: "button" } : {}}
    >
      {children}
    </Element>
  );
};

Category.propTypes = {
  children: PropTypes.string.isRequired,
  href: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

Category.defaultProps = {
  isSelected: false,
};

export default Category;

import React from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

import "./_style.scss";

const PageBlockAction = ({
  action,
  actionCopy,
  isCentered,
  ...other
}) => (
  <div
    className={
        classNames({
          "page-block-action": true,
          "--centered": isCentered,
        })
      }
    {...other}
  >
    {actionCopy
        && (
        <div className="page-block-action__copy">
          {actionCopy}
        </div>
        )}
    <div className="page-block-action__cta">
      {action}
    </div>
  </div>
);

PageBlockAction.propTypes = {
  action: PropTypes.node.isRequired,
  actionCopy: PropTypes.node,
  isCentered: PropTypes.bool,
};

export default PageBlockAction;

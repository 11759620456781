import React from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

import "./_style.scss";

const CategoryFilter = ({
  align,
  children,
  ...other
}) => (
  <div
    className={
        classNames({
          "category-filter": true,
          [`--align-${align}`]: align,
        })
      }
    {...other}
  >
    {React.Children.map(children, (child) => (
      <div className="category-filter__item">
        {child}
      </div>
    ))}
  </div>
);

CategoryFilter.propTypes = {
  align: PropTypes.oneOf(["left", "center", "right"]),
  children: PropTypes.node.isRequired,
};

export default CategoryFilter;
